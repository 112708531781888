<template>
  <div class="container">
    <div class="page-title">
      <h1>{{ $t('ACCOUNT.title') }}</h1>
    </div>
    <div class="row">
       <!-- justify-content-center -->
      <div class="col-12 col-md-6">
        <b-alert variant="light" show>
          <div v-if="currentUser && currentUser.subscription_package && currentUser.subscription_package.package">
            <p v-html="$t('ACCOUNT.current_plan', { package: friendlyPackage })"></p>
            <p
              v-if="currentUser.subscription_package.package != 'free'"
              v-html="$t('ACCOUNT.expires_at', { at: currentUser.subscription_package.ends_at })">
            </p>
          </div>

          <div v-else>
            <p class="lead">
              {{ $t('ACCOUNT.no_plan') }}
            </p>
          </div>

          <b-btn variant="primary" :to="{ name: 'Upgrade' }">Conheça nossos planos e atualize</b-btn>

          <hr>

          <b-btn v-if="shouldShowCustomerPortal" variant="secondary" @click.stop.prevent="redirectToCustomerPortal">Quer alterar sua assinatura?</b-btn>
        </b-alert>
      </div>

      <div class="col-12 col-md-6">
        <!-- <h4>oi</h4> -->
        <div v-if="subscriptions.length > 0">
          <h4>{{ $t('ACCOUNT.subscriptions.title') }}</h4>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>{{ $t('ACCOUNT.subscriptions.created_at') }}</th>
                <th>{{ $t('ACCOUNT.subscriptions.status') }}</th>
                <th>{{ $t('ACCOUNT.subscriptions.ends_at') }}</th>
              </tr>
            </thead>
            <tr v-for="subscription in subscriptions" :key="subscription.id">
              <td>{{subscription.created_at_friendly}}</td>
              <td>
                {{ friendlyStatus(subscription.status) }}
                <a
                  :href="subscription.payment_url"
                  v-if="['subscription_status_pending', 'subscription_status_sent'].includes(subscription.status)"
                  class="btn btn-success btn-sm"
                >
                  {{ $t('ACCOUNT.subscriptions.pay_now') }}
                </a>
              </td>
              <td>{{subscription.ends_at_friendly}}</td>
            </tr>
          </table>
        </div>
      </div>

    </div>


    <div class="row justify-content-center text-center">
      <div class="col col-md-8">
        <!-- <br> -->

        <!-- needsProfileUpdate && userNotPremium -->
        <div v-if="false">
          <p>{{ $t('ACCOUNT.needs_update.title') }}</p>
          <b-btn size="lg" v-b-modal.modalProfileUpdate variant="success">
            {{ $t('ACCOUNT.needs_update.subtitle') }}
          </b-btn>
          <b-modal
            id="modalProfileUpdate"
            ref="modalProfileUpdate"
            :title="$t('ACCOUNT.needs_update.modal.title')"
            :hide-footer=true
          >
            <form @submit.stop.prevent="handleProfileSubmit">
              <b-form-input type="text" :placeholder="$t('ACCOUNT.needs_update.modal.cpf')" v-model="user.document_cpf"></b-form-input>
              <b-form-input type="text" :placeholder="$t('ACCOUNT.needs_update.modal.cellphone')" v-model="user.cellphone"></b-form-input>

              <b-btn type="submit" variant="primary">
                {{ $t('ACCOUNT.needs_update.modal.submit') }}
              </b-btn>
            </form>
          </b-modal>
        </div>

        <!-- currentUser.subscription_package.package == 'free' -->
        <div v-if="false">
          <b-alert show variant="success">
            <!-- <h4 class="alert-heading">Migre para o Plano PREMIUM</h4> -->
            <h4 class="alert-heading">
              {{ $t('ACCOUNT.no_premium.title') }}
            </h4>
            <div class="text-left">
              <ul>
                <li v-html="$t('ACCOUNT.no_premium.private_upload')">
                </li>
                <li>{{ $t('ACCOUNT.no_premium.private_share') }}</li>
                <li v-html="$t('ACCOUNT.no_premium.collections')">
                </li>
              </ul>

              <div class="text-center">
                <!-- <br> -->
                <!-- <small>pagamentos via Picpay - virá na fatura QUEOTA.club, nossa empresa mãe</small> -->
              </div>
            </div>
          </b-alert>
        </div>

      </div>
    </div>

    <!-- <PlansTable :currentPlan="'solo'"></PlansTable> -->
  </div>
</template>

<script>
import PlansTable from "@/components/PlansTable";
export default {
  name: "Account",
  metaInfo() {
    return {
      title: "Meu Plano"
    };
  },
  data() {
    return {
      user: { cellphone: "", document_cpf: "" },
      subscriptions: [],
      checkoutSubscription: {
        coupon_code: '',
        plan: ''
      },
      currentCoupon: {
        name: ''
      },
      shouldShowCustomerPortal: false,
    };
  },
  computed: {
    needsProfileUpdate: function() {
      return !(
        this.currentUser.cellphone &&
        this.currentUser.cellphone != "" &&
        this.currentUser.document_cpf &&
        this.currentUser.document_cpf != ""
      );
    },
    userNotPremium: function() {
      return !(
        this.currentUser &&
        this.currentUser.subscription_package &&
        this.currentUser.subscription_package.package &&
        this.currentUser.subscription_package.package == "free"
      );
    },
    friendlyPackage: function() {
      switch (this.currentUser.subscription_package.package) {
        case "free":
          return "gratuito";
          break;
        case "subscription_package_solo":
          return "PREMIUM";
          break;
        case "subscription_package_team":
          return "TIMES";
          break;
        case "subscription_package_full":
          return "COMPLETO";
          break;
      }
    }
  },
  methods: {
    initialize() {
      this.user.document_cpf = this.currentUser.document_cpf;
      this.user.cellphone = this.currentUser.cellphone;

      this.$http
        .get("subscriptions.json")
        .then(response => {
        this.subscriptions = response.data
        this.shouldShowCustomerPortal = this.subscriptions.length > 0;
      });

      this.checkStripeReturn();
    },
    checkStripeReturn() {
      // const stripe = window.Stripe(`pk_test_51I8QnRL2jsPOObrkUm2MYXbgwL0ZBD14YjktIZPkGYf5O5bkjkd6frCSsZmjoof4z4PcoWrB06Ht8iv0k5d3IsNL00VZDFlGN3`)
      const stripe = window.Stripe(`pk_live_51I8QnRL2jsPOObrk8zPj53DZboTTujTKN3vw3lfCZEEK27WahBpyAeurV1aU81eNif00ohNFiBeqg5KnOj0eZOA000EkEEzIBG`)
      const params = new URLSearchParams(window.location.search);

      // params.get('stripeReturn') === "canceled"

      // params.get('stripeReturn') === "success"
      // params.get('session_id')

    },
    redirectToCustomerPortal() {
      // const stripe = window.Stripe(`pk_test_51I8QnRL2jsPOObrkUm2MYXbgwL0ZBD14YjktIZPkGYf5O5bkjkd6frCSsZmjoof4z4PcoWrB06Ht8iv0k5d3IsNL00VZDFlGN3`)
      const stripe = window.Stripe(`pk_live_51I8QnRL2jsPOObrk8zPj53DZboTTujTKN3vw3lfCZEEK27WahBpyAeurV1aU81eNif00ohNFiBeqg5KnOj0eZOA000EkEEzIBG`)

      this.$http
        .post("subscriptions/customer_portal.json", { })
        .then(response => {
          window.location.href = response.data.redirect_url;
          // customer_portal
          // stripe
          //   .redirectToCheckout({
          //     sessionId: response.data.payment_url //#.sessionId
          //   })
          //   .then(this.handleResult);
        });

      // const stripe = window.Stripe(`pk_live_51I8QnRL2jsPOObrk8zPj53DZboTTujTKN3vw3lfCZEEK27WahBpyAeurV1aU81eNif00ohNFiBeqg5KnOj0eZOA000EkEEzIBG`)
      // stripe
      //   .redirectToCheckout({
      //     sessionId: response.data.payment_url //#.sessionId
      //   })
      //   .then(this.handleResult);

    },
    redeemCoupon() {
      this.$http.get(`subscriptions/redeem_coupon.json?coupon_code=${this.checkoutSubscription.coupon_code}`).then((response) => {
        this.currentCoupon = response.data;
      })
    },
    handleProfileSubmit() {
      this.$http
        .patch(`users/current_user.json`, { user: this.user })
        .then(response => {
          this.$store.dispatch("updateUserProfile", response.data);
          this.initialize();
          this.$refs.modalProfileUpdate.hide();
        });
    },
    subscribePackage(planName) {
      // ,package: planName
      this.$http
        .post("subscriptions.json", {
          subscription: {
            amount: 29.99
          }
        })
        .then(response => {
          window.location.href = response.data.payment_url;
        });
    },
    friendlyStatus(status) {
      switch (status) {
        case "subscription_status_pending":
          return "Aguardando pagamento";
          break;
        case "subscription_status_sent":
          return "Aguardando pagamento";
          break;
        case "subscription_status_error":
          return "Erro - fale conosco";
          break;
        case "subscription_status_payed":
          return "Pago";
          break;
      }
    }
  },
  mounted() {
    this.initialize();
  },
  components: {
    PlansTable
  }
};
</script>
